.dialog {
  padding: var(--offset-lg) var(--offset-x-lg);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--offset-sm);
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
