.title {
  font-size: 32px;
  color: var(--light-blue);
  font-weight: 700;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;

  background-color: var(--grey-7);

  padding: var(--offset-sm);
  margin-top: var(--offset-sm);
  gap: var(--offset-xx-sm);
}

.step {
  font-size: var(--offset-sm);
}

.stepNumber {
  color: var(--darker-blue);
  font-weight: bold;
}

.templateLink {
  color: var(--pink);
}

.controlButton {
  width: 180px;
}

.stepperWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--offset-sm);
}
