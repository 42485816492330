.loaderChooserContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.button {
  width: 500px;
  padding-left: 50px;
  padding-right: 50px;
}

.skipButton {
  width: 300px;
  margin-top: var(--offset-lg);
  color: var(--new-gray1);
}

.controlButton {
  width: 180px;
}

.stepperWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--offset-sm);
}
