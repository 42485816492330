.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 40px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  margin-top: 100px;
  width: 500px;
  height: 630px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 100px 50px 100px;
  position: relative;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}

.emailButton {
  width: 268px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--pink);
  margin-top: var(--offset-x-sm);
  padding: var(--offset-xx-sm);

  border-radius: 5px;

  text-decoration: none !important;

  .emailText {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
    color: var(--white);
    text-align: center;
  }
}

.referButton {
  width: 268px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--new-blue6);
  margin-top: var(--offset-x-sm);
  padding: var(--offset-xx-sm);

  border-radius: 5px;

  text-decoration: none !important;

  .referText {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
    color: var(--new-blue1);
    text-align: center;
  }
}

.supportButton {
  width: 268px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: var(--offset-x-sm);
  padding: var(--offset-xx-sm);

  border-radius: 5px;
  border: 1px solid var(--new-grey3);

  text-decoration: none !important;

  .supportText {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
    color: var(--new-grey3);
    text-align: center;
    margin-left: var(--offset-xxx-sm);
  }

  > svg {
    width: 20px;
    height: 20px;
  }
}

.thankYouText {
  width: 410px;
  margin: var(--offset-xx-sm) 0;
}

.welcomeText {
  margin: var(--offset-xx-sm) 0;
}

.trialText {
  font-size: 12px;
  width: 410px;
  position: absolute;
  bottom: 10px;
}
