.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 30px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  margin-top: 100px;
  width: 735px;
  height: 800px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 30px 50px 30px;
  position: relative;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}
.title {
  text-transform: initial;
  font-size: 36px;
  margin-bottom: var(--offset-xx-lg);
}

.passwordTitle {
  font-weight: bold;
}
.subtitle {
  margin-top: var(--offset-xx-sm);
}
.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 500px;
  height: 283px;

  .names {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--offset-sm);
    margin-top: var(--offset-sm);

    .name {
      width: 45%;
    }
  }
}

.inputField {
  width: 100%;
  margin-bottom: var(--offset-sm);
}

.error {
  text-align: right;
  width: 100%;
  padding-bottom: var(--offset-sm);
}

.grayLine {
  width: 500px;
  height: 1px;
  background-color: var(--new-grey5);
  margin-bottom: var(--offset-lg);
  margin-top: var(--offset-xx-lg);
}

.googleButton {
  background: #4285f4;
  color: #fff;
  border: #4285f4 1px solid;
  border-radius: 0;
  height: 40px;
  width: 500px;
  padding: 0;
}
.googleButton:hover {
  background: #4285f4;
}
.googleButtonLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  text-transform: none;
}
.googleButtonIcon {
  width: 38px;
  height: 38px;
  margin-left: 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cleverButton {
  background: var(--white);
  color: #4274f6;
  border: 1px solid #7b9ef9;
  border-radius: 3px 3px 0px 0px;
  height: 40px;
  width: 500px;
  padding: 0;
}
.cleverButton:hover {
  background: var(--white);
}
.cleverButtonLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  text-transform: none;
}
.cleverButtonIcon {
  width: 35px;
  height: 30px;
  margin-left: 5px;
  margin-top: 1px;
  border-right: 1px solid #bdcefc;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signUpButton {
  margin-top: var(--offset-xxx-lg);
}
