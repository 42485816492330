.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 40px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  margin-top: 100px;
  width: 500px;
  height: 630px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 80px 50px 80px;
  position: relative;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}
.title {
  font-size: 64px;
  margin-top: var(--offset-xx-lg);
  text-transform: initial;
}

.description {
  margin-top: var(--offset-xx-lg);
  font-style: italic;
  font-weight: bold;
  text-align: center;
}

.grayLine {
  width: 100%;
  height: 1px;
  background-color: var(--new-grey5);
  margin-top: var(--offset-xx-sm);
}

.roster {
  text-align: start;
  width: 100%;
}
.rosterText {
  margin-top: var(--offset-xx-sm);
  margin-bottom: var(--offset-xx-sm);
}

.rosterButton {
  background-color: var(--new-blue3);
  margin-bottom: var(--offset-md);
  width: 100%;
}
