.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 30px;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  margin-top: 100px;
  width: 735px;
  height: 700px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 30px 50px 30px;
  position: relative;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}
.title {
  text-transform: capitalize;
  font-size: 36px;
  margin-bottom: var(--offset-sm);
}

.boxContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: var(--offset-md);
}

.box {
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--new-grey7);

  padding: 0 var(--offset-xx-sm);

  :first-child {
    margin-left: var(--offset-xx-sm);
  }
}

.button {
  width: 252px;
  margin-top: var(--offset-xx-sm);
}

.qrCodeText {
  margin-top: var(--offset-md);
  margin-bottom: var(--offset-md);
}
