@import '_scss-variables.scss';

.headerContainer {
  max-width: 1010px;
  margin: 0 var(--offset-sm);
}

.paperContainer {
  max-width: 1010px;
  margin: 0 var(--offset-sm) var(--offset-x-sm) var(--offset-sm);

  @media screen and (max-width: $max-tablet-screen) {
    margin: 0 0 var(--offset-x-sm) 0;
  }
}

.panel {
  border-radius: 5px;

  @media screen and (max-width: $max-tablet-screen) {
    border: none;

    border-bottom: 1px solid var(--new-grey5);
    border-radius: 0;
  }
}

.panelSummary {
  cursor: default !important;
  padding: var(--offset-sm);

  & > * {
    /* There is a bug with Material UI. This removes the border bottom from the Expansion panel close button */
    border-bottom: none !important;
  }
}

.withBorder {
  border-bottom: 1px solid var(--grey-1);

  @media screen and (max-width: $max-tablet-screen) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.noBorder {
  display: block;
  border-bottom: none;
  margin: 0;
}

.accordionDetails {
  @media screen and (max-width: $max-tablet-screen) {
    padding: var(--offset-sm);
  }
}

.aiGraderButton {
  margin-bottom: var(--offset-sm);
}
