.freeTrialReminder {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  width: 100%;
  height: auto;
  background-color: var(--new-blue2);
  padding: 5px;
  border-radius: 4px;
}

.progress {
  width: 100%;
  background-color: var(--white);
  height: 10px;
  border-radius: 5px;
}

.upgradeLink {
  color: var(--white);
  text-decoration: underline;
}
