.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}

.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 40px;
}

.container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.paper {
  margin-top: 100px;
  height: fit-content;
  min-width: 830px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 100px 50px 100px;
  position: relative;
}

.stepper {
  margin-bottom: 30px;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}
