.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 40px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.paper {
  margin-top: 100px;
  height: fit-content;
  min-width: 830px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 100px 50px 100px;
  position: relative;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}

.emailButton {
  width: 268px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--pink);
  margin-top: var(--offset-xxx-lg);
  margin-bottom: var(--offset-md);
  padding: var(--offset-xx-sm);

  border-radius: 5px;

  text-decoration: none !important;

  .emailText {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
    color: var(--white);
    text-align: center;
  }
}

.subtitle {
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  margin-top: var(--offset-xx-sm);
}

.team {
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: var(--offset-md);
}

.teamMate {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--new-grey7);

  :first-child {
    margin-left: var(--offset-xx-sm);
  }
}

.teamMateImage {
  width: 231px;
  height: 200px;
}
.teamMateText {
  font-style: italic;
  margin-bottom: var(--offset-xx-sm);
}

.header {
  margin-top: var(--offset-x-sm);
}
