.inputContainer {
  width: 480px;
  margin: var(--offset-lg) auto 0 auto;
  text-align: left;

  @media only screen and (max-width: 670px) {
    width: 100%;
  }

  .inputLabel {
    display: flex;
    margin-bottom: var(--offset-xx-sm);
    align-items: center;
  }

  .textField {
    .icon {
      opacity: 0.5;
    }

    input:focus + * .icon {
      opacity: 1;
    }
  }
}

.phoneInput {
  > input {
    width: 100% !important;
  }
}

.controlButton {
  width: 180px;
}

.stepperWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--offset-sm);
}

.tooltipWrapper {
  margin-left: var(--offset-xxx-sm);
}
.tooltipInfo {
  background-color: var(--light-grey);
}

.submitButton {
  margin-top: var(--offset-x-lg);
}

.loaderContainer {
  display: flex;
  justify-content: center;

  margin-top: var(--offset-x-lg);
}
